import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    var subject = "One times two plus one equals three.";

    // Solution 1:

    var regex = /\b(?:one|two|three)\b/gi;

    subject.match(regex);
    // Returns an array with four matches: ["One","two","one","three"]
  }

}
