<!-- Footer -->
<footer id="ftr" class="page-footer font-small stylish-color" style="bottom: 0 !important;">


  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5">
    <br />
    <!-- Grid row -->
    <div class="row mt-3">

      <!-- Grid column -->
      <div class="col-md-4 mx-auto mb-4">

        <!-- Content -->
        <h6 class="text-uppercase font-weight-bold">Hitachi Astemo Brake Systems India Private Limited.</h6>
        <hr class="danger-color mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          A606 and B606, 6th Floor,
          International Convention Centre, MCCIA Trade Towers,
          Senapati Bapat Road, Shivaji Nagar
          Pune 411016.
          MH, India.<br><br>
          <strong>Phone:</strong> +91 9607967675<br>
          <strong>Email:</strong> India.customercare@hitachiastemo.com<br>
          <strong>CIN No:</strong> U34200PN2011PTC140066<br>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3 mx-auto mb-4">

        <!-- Links -->
        <!-- <h6 class="text-uppercase font-weight-bold">Products</h6>
        <hr class="danger-color mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a href="#!">Astemo Products</a>
        </p>
        <p>
          <a href="#!">KBX Products</a>
        </p>
        <p>
          <a href="#!">Hitachi Products</a>
        </p>
        <p>
          <a href="#!">Keihin Products</a>
        </p> -->

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3  mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Useful links</h6>
        <hr class="danger-color mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a href="#!">User Manual/User Guide</a>
        </p>
        <p>
          <a [routerLink]="'/login'" routerLinkActive="active" mdbWavesEffect>Become a Dealer</a>
        </p>
        <p>
          <a href="https://www.hitachiastemo.com/en/" target="_blank">Hitachi Astemo, Ltd.</a>
        </p>
        <p>
          <a [routerLink]="'/contactus'" routerLinkActive="active" mdbWavesEffect>Contact Us</a>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->

      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© {{Year}} Copyright:
    <a href="https://www.hitachiastemo.com/en/" target="_blank">Hitachi Astemo Brake Systems India Private Limited.</a>


  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->