<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light mdb-color white fixed-top" [containerInside]="false">

  <!-- Navbar brand -->
  <mdb-navbar-brand >
    <img id="navbar-brand"
      src="assets/img/Hitachi-Logo.png"
      style="height: 46px; width: 185px;float: right; padding-right: 10px; position: relative; " alt="Hitachi Lopgo"/>
  </mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>
    <!-- Links -->
    <ul class="navbar-nav ml-auto" id="aln">
      <li class="nav-item">
        <a class="nav-link waves-light" [routerLink]="'/home'" routerLinkActive="active" mdbWavesEffect>Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" [routerLink]="'/aboutus'" routerLinkActive="active" mdbWavesEffect>About us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" [routerLink]="'/products'" routerLinkActive="active" mdbWavesEffect>Guest Login</a>
      </li>

      <li class="nav-item">
        <a class="nav-link waves-light" [routerLink]="'/login'" routerLinkActive="active" mdbWavesEffect> Login</a>
      </li>

      <!-- <li class="nav-item">
        <a class="nav-link waves-light" [routerLink]="'/dealerlogin'" routerLinkActive="active" mdbWavesEffect>Dealer Login</a>
      </li> -->

      <li class="nav-item">
        <a class="nav-link waves-light" [routerLink]="'/contactus'" routerLinkActive="active" mdbWavesEffect>Contact
          Us</a>
      </li>


    </ul>
    <!-- Links -->
  </links>
  <!-- Collapsible content -->
</mdb-navbar>
<!--/.Navbar-->