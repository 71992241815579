import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit {
  sub: any;
  id: any;
  id2: any;

  constructor(private myRoute: ActivatedRoute,) { }

  ngOnInit() {

    this.sub = this.myRoute.params.subscribe(params => {
      this.id = params['id'];
      this.id2 = params['id2'];
      });
      //console.log(this.id);
      //console.log(this.id2);
  }

}
