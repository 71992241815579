import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainoutletComponent } from './mainoutlet/mainoutlet.component';
import { HomeComponent } from './home/home.component';
import { TestingComponent } from './testing/testing.component';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [

  {

    path: '', component: MainoutletComponent, children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
      },
      { path: 'home', component: HomeComponent },

      { path: 'detailView/:id/:id2', component: TestingComponent },
      {
        path: 'aboutus', loadChildren: () => import('./aboutus/aboutus.module').then(module => module.AboutusModule)
      },
      {
        path: 'products', loadChildren: () => import('./products/products.module').then(module => module.ProductsModule)
      },
      {
        path: 'login', loadChildren: () => import('./login/login.module').then(module => module.LoginModule)
      },
      {
        path: 'contactus', loadChildren: () => import('./contactus/contactus.module').then(module => module.ContactusModule)
      },
      
    ]
  },

  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin/admin.module').then(
      module => module.AdminModule
    )
  },











  { path: 'home', component: HomeComponent },
  { path: '**', redirectTo: '/home' }
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
