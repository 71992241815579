

import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CommonModule ,HashLocationStrategy,LocationStrategy} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from '../app/material.module';
import { NavComponent } from './Shared/nav/nav.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { TestingComponent } from './testing/testing.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { MainoutletComponent } from './mainoutlet/mainoutlet.component';
@NgModule({
  declarations: [
    AppComponent,
    MainoutletComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    TestingComponent
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    MaterialModule,
    HttpClientModule,
    ToastrModule.forRoot(),// ToastrModule added
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [    
    AuthService,
    AuthGuard,
    // {provide:LocationStrategy,useClass:HashLocationStrategy} // if AWS build remove it or Add it of it is on RDP;
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
