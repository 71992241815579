import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable()
export class AuthService {
    constructor(private myRoute: Router) { }
    sendToken(token: string) {
        sessionStorage.setItem("Userkey", token)
    }
    getToken() {
        return sessionStorage.getItem("Userkey")
    }
    isLoggednIn() {
        return this.getToken() !== null;
    }
    logout() {
        sessionStorage.removeItem("Userkey");
        this.myRoute.navigate(["login"]);
    }
}