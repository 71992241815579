<div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel" style="margin-top: 60px;">

  <div class="carousel-inner">
    <div class="carousel-item active" id="iim">
      <img class="d-block w-100"
        src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604482961/Chassis_Brakes/s1_dtxwki.jpg"
        alt="First slide">

    </div>
    <div class="carousel-item" id="iim">
      <img class="d-block w-100"
        src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604483141/Chassis_Brakes/s2_u4qxbh.jpg"
        alt="Second slide">
    </div>
    <div class="carousel-item" id="iim">
      <img class="d-block w-100" 
        src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604483275/Chassis_Brakes/s3_z9cy8z.jpg"
        alt="Third slide">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
    <span aria-hidden="true"><i class="material-icons">
        arrow_left
      </i></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
    <span aria-hidden="true"><i class="material-icons">
        arrow_right
      </i></span>
    <span class="sr-only">Next</span>
  </a>
</div>



<main id="main" style="margin-top: -3%;">
  <!--NextGen Skill Development Section -->
  <section id="about" class="about">
    <div class="container">
      <!-- <a class="btn btn-white-view" [routerLink]="[ '/detailView',12345]">view</a> -->
      <div class="section-title" data-aos="fade-up" data-aos-delay="200">
        <h2> <a class="effect-underline">Hitachi Automotive Systems</a></h2>
        <p>On January 1, 2021, four automotive parts manufacturers (Hitachi Automotive Systems, Keihin Corporation, Showa Corporation, and Nissin Kogyo Co., Ltd.) merged to form Hitachi Astemo, Ltd., a leader in technological innovation. This new company will serve as a global supplier of mobility solutions for automobile and motorcycle industries worldwide.</p>
        <p>Combining the first letters of the words "Advanced Sustainable Technologies for Mobility" to form "Astemo", the new name clearly describes the mission of the integrated company: "to provide a safe, sustainable, and comfortable mobility life through technologies that contribute to an advanced and sustainable society".</p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <img data-aos="zoom-in" data-aos-delay="200"
            src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604483524/Chassis_Brakes/businessmen-brainstorming-in-the-workplace-vector_f3twkh.jpg"
            class=" rounded img-fluid hoverable" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content">
          <br>
          <h3 style="margin-top: 10px;" ><strong>Advanced Technologies</strong></h3>
          <br>
          <h5 style="margin-top: 10px;" ><strong>Technologies that shape the future</strong></h5>
          <p><strong>
            

            Combining the strengths of the four companies, the integrated company will provide advanced mobility solution in the CASE field that outpace the rapid changes of our era.

            </strong>
          </p>

        </div>
      </div>



      <div class="row" style="margin-top: 5%;">
        <div class="col-lg-6 content" data-aos="fade-right">
          <h3 style="margin-top: 10px;"><strong>Sustainable Technologies</strong></h3>
          <br>
          <h5 style="margin-top: 10px;"><strong>Technologies that contribute to a sustainable society</strong></h5>
          <p><strong>

            The integrated company will develop and deploy technologies that contribute to a prosperous, safe, secure, and environmentally sound future society for our children and grandchildren.
            </strong>
          </p>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content">
         
          <img data-aos="fade-left"
            src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604484802/Chassis_Brakes/188-1887596_supply-and-demand-planning-illustration-hd-png-download_btzzlz.png"
            class=" rounded img-fluid hoverable" alt="">
        </div>
      </div>



      <div class="row" style="margin-top: 5%;">
        
        <div class="col-lg-6">
          <img data-aos="zoom-in" 
          src="https://res.cloudinary.com/dhqpvur6n/image/upload/v1604484488/Chassis_Brakes/246-2462149_teamwork-vector-hd-png-download_ce5uy1.png"
          class=" rounded img-fluid hoverable" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content">
          <h3 style="margin-top: 10px;"><strong>Mobility</strong></h3>
          <br>
          <h5 style="margin-top: 10px;"><strong>Contributing to a safe and comfortable mobility life</strong></h5>
          <p><strong>
                        The integrated company will contribute to a society where all people can enjoy a life supported by advanced, safe, and secure mobility in a clean environment, in a world where traffic accidents have been eliminated.
            </strong>
          </p>
        </div>
      </div>
    </div>
  </section>


  


  
  


</main>



