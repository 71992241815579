<!-- <div class="wrapper">
  <app-nav></app-nav>
  <router-outlet (activate)="onActivate($event, outlet)" #outlet ></router-outlet>
  
  <div style="margin-top: 50px;">
    <app-footer></app-footer>
  </div>
</div> -->





<div class="main-panel ">
  <app-nav></app-nav>
  <router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>
</div>
<div style="margin-top: 20px;">
  <app-footer></app-footer>
</div>